import * as React from "react";
import useIsSmallDevice from "../../../utils/hooks/use-is-small-device";
import CostSectionBadges from "./cost-section-badges";
import {
  CostSectionContainer,
  CostSectionDescription,
  CostSectionItemsWrapper,
  CostSectionTextWrapper,
  CostSectionWrapper,
} from "./cost-section.components";

interface Props {
  ref?: React.Ref<HTMLDivElement>;
  children?: React.ReactNode;
}

const CostSection: React.FunctionComponent<Props> = React.forwardRef<
  HTMLDivElement,
  Props
>(({ children }, ref) => {
  const isSmallDevice = useIsSmallDevice();

  return (
    <CostSectionContainer ref={ref}>
      <CostSectionWrapper>
        <CostSectionItemsWrapper>
          <CostSectionTextWrapper>
            <h1>Ile to kosztuje?</h1>
            {isSmallDevice && <CostSectionBadges />}
            <CostSectionDescription>
              KonopnyMed przeprowadza konsultacje w formie teleporad. Recepta
              będzie dostępna na Twoim koncie na stronie pacjent.gov.pl, a na
              podany numer telefonu otrzymasz wiadomość z kodem potrzebnym do
              realizacji recepty. Z nami załatwisz wszelkie formalności online i
              dzięki teleporadzie.
              <br />
              <br /> Chcesz zostać pacjentem medycznej marihuany, ale nie wiesz
              jak się za to zabrać? Myślisz, że sam proces kwalifikacji lub
              przeniesienia do naszej poradni, to droga przez mękę?
              <br />
              <br /> Nic bardziej mylnego! Jesteśmy tu, aby Ci pomóc! Wszystko
              załatwisz dzięki teleporadzie, w przeciągu maksymalnie 24. godzin
              i w bardzo przystępnej cenie! Po wypełnieniu kwestionariusza
              osobowego, nasi specjaliści niezwłocznie podejmą z Tobą kontakt i
              zakwalifikują do najodpowiedniejszej formy terapii.
            </CostSectionDescription>
            {children}
          </CostSectionTextWrapper>
          {!isSmallDevice && <CostSectionBadges />}
        </CostSectionItemsWrapper>
      </CostSectionWrapper>
    </CostSectionContainer>
  );
});

export default CostSection;
