import styled from "@emotion/styled";

export const CostSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: white;
`;

export const CostSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  padding-inline: 48px;
  box-sizing: border-box;

  margin-block: 140px;

  @media ${(props) => props.theme.mq.tablet} {
    width: 100%;
    margin-block: 80px;

    padding-inline: 16px;
  }
`;

export const CostSectionDescription = styled.p`
  font-size: 16px;
  margin-block: 32px;

  max-width: 715px;
`;

export const CostSectionItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  justify-content: space-between;

  @media ${(props) => props.theme.mq.tablet} {
    flex-direction: column;
  }
`;

export const CostSectionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-right: 80px;
  @media ${(props) => props.theme.mq.tablet} {
    margin: 0;
  }
`;

export const CostSectionBadgesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  justify-content: flex-end;
`;

export const CostSectionBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: fit-content;

  padding: 32px 24px;
  border-radius: 32px;

  background-color: ${(props) => props.theme.colors.lightOrange};

  &:first-of-type {
    margin-bottom: 32px;
    background-color: ${(props) => props.theme.colors.brand};
  }

  @media ${(props) => props.theme.mq.tablet} {
    padding: 16px;

    &:first-of-type {
      margin: 32px 0 16px;
    }
  }
`;

export const CostSectionBadgeTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 24px;

  @media ${(props) => props.theme.mq.tablet} {
    margin-left: 16px;
    font-size: ${(props) => props.theme.fonts.sizes.xxs};
  }
`;

export const CostSectionHeading = styled.h2`
  color: ${(props) => props.theme.colors.bg};
  font-size: 31px;
  font-weight: 700;

  margin-bottom: 16px;

  @media ${(props) => props.theme.mq.tablet} {
    margin-bottom: 8px;
    font-size: ${(props) => props.theme.fonts.sizes.s};
  }
`;

export const IconWrapper = styled.div`
  height: 72px;
  width: 72px;
`;
