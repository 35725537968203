import * as Yup from "yup";

export interface QuestionnaireFormValues {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  zipCode: string;
  phoneNumber: string;
  email: string;
  pesel: string;
  treatmentStatus: "Kwalifikacja" | "Kontynuacja";
  description: string;
  diseases: string;
  files: File[];
  medications: string;
  acceptsTerms: boolean;
}

export const questionnaireValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Pole wymagane"),
  lastName: Yup.string().required("Pole wymagane"),
  address: Yup.string().required("Pole wymagane"),
  city: Yup.string().required("Pole wymagane"),
  zipCode: Yup.string()
    .required("Pole wymagane")
    .matches(/[0-9]{2}-[0-9]{3}/, "Niepoprawny format"),
  phoneNumber: Yup.string()
    .required("Pole wymagane")
    .matches(/^\d+$/, "Niepoprawny format"),
  email: Yup.string().email("Niepoprawny format").required("Pole wymagane"),
  pesel: Yup.string()
    .required("Pole wymagane")
    .matches(/^\d+$/, "Niepoprawny format"),
  treatmentStatus: Yup.string(),
  description: Yup.string().required("Pole wymagane"),
  diseases: Yup.string(),
  medications: Yup.string(),
  acceptsTerms: Yup.boolean().isTrue(),
});

export const questionnaireInitialValues: QuestionnaireFormValues = {
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  zipCode: "",
  phoneNumber: "",
  email: "",
  pesel: "",
  treatmentStatus: "Kwalifikacja",
  description: "",
  files: [],
  diseases: "",
  medications: "",
  acceptsTerms: false,
};
