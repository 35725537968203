import * as React from "react";
import Button from "../../../shared/button";
import { TermsWrapper, Text } from "./terms.components";

interface Props {
  close: () => void;
}

const Banner: React.FunctionComponent<Props> = ({ close }) => {
  return (
    <TermsWrapper>
      <Text>
        Drogi Pacjencie,
        <br />
        <br />
        Jeśli masz jakiekolwiek pytania tudzież wątpliwości przed wysłaniem
        <br />
        kwestionariusza zgłoszeniowego, bardzo prosimy o kontakt telefoniczny
        <br />
        791265907 lub wiadomość e-mail{" "}
        <a href="konopnymed@gmail.com">konopnymed@gmail.com</a>
        <br />
        <br />
        Pamiętaj, jesteśmy tu po to aby Ci pomóc,
        <br />
        <br />
        mgr farm. Bartłomiej Zalewa
      </Text>

      <Button onClick={close}>Zamknij</Button>
    </TermsWrapper>
  );
};

export default Banner;
