import * as React from "react";
import useIsSmallDevice from "../../../utils/hooks/use-is-small-device";
import { Divider } from "../../shared/divider";
import Clock from "./clock.svg";
import IllustrationMobile from "./illustration-mobile.svg";
import {
  Heading,
  HeadingCaption,
  InfoContainer,
  IntroductionContainer,
  IntroductionWrapper,
  LeftContainer,
  PitchContainer,
  PitchHeader,
  PitchHeaderWrapper,
  PitchItem,
  StyledIllustration,
} from "./introduction-section.components";
import Leaf from "./leaf.svg";

interface Props {
  children?: React.ReactNode;
}

const IntroductionSection: React.FunctionComponent<Props> = ({ children }) => {
  const pitchItems = [
    {
      title: "100% naturalnie",
      description: "Dla osób chcących leczyć się",
      description2: "naturalnymi metodami",
      icon: <Leaf />,
    },
    {
      title: "max 24h",
      description: "Dzięki teleporadzie, wszystkie formalności",
      description2: "załatwisz w przeciągu 24 godzin",
      icon: <Clock />,
    },
  ];
  const isSmallDevice = useIsSmallDevice();

  return (
    <IntroductionContainer>
      <IntroductionWrapper>
        <InfoContainer>
          <LeftContainer>
            <PitchContainer>
              {pitchItems.map((item) => (
                <PitchItem key={item.title}>
                  <PitchHeaderWrapper>
                    {item.icon}
                    <PitchHeader>{item.title}</PitchHeader>
                  </PitchHeaderWrapper>
                  <p>{item.description}</p>
                  <p>{item.description2}</p>
                </PitchItem>
              ))}
            </PitchContainer>
            <Divider marginBlock={40} />
            <div>
              <Heading>Rozpocznij terapię</Heading>
              <Heading>Medyczną Marihuaną</Heading>
              <HeadingCaption>
                Wypełnij kwestionariusz i sprawdź, czy kwalifikujesz się do
                terapii MM już dziś
              </HeadingCaption>
              {children}
            </div>
            {isSmallDevice && <IllustrationMobile />}
          </LeftContainer>
        </InfoContainer>
        {!isSmallDevice && <StyledIllustration />}
      </IntroductionWrapper>
    </IntroductionContainer>
  );
};

export default IntroductionSection;
