import styled from "@emotion/styled";

export const SwitchFieldWrapper = styled.div<{ isError: boolean }>`
  display: flex;
  flex-direction: column;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: fit-content;

  padding: 4px;

  border: 1px solid ${(props) => props.theme.colors.bg};
  border-radius: 24px;

  & > :not(:first-of-type) {
    margin-left: 6px;
  }
`;

export const SwitchOption = styled.p`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;

  padding: 4px 14px;

  color: ${(props) => props.theme.colors.brand};
`;
